import axios from 'axios';
import Const from '@/Const';
import wx from "weixin-js-sdk";
import { router } from '../../router';

function text(data) {
  window.console.log(data)
}

function checkReg() {
  // // 规则对象(flag)
  //  var flag = new RegExp("[`^&*()=|{}':',\\[\\].<>]")
  //  //var flag=new RegExp("/^((?!\\|\/|:|\*|\?|<|>|\||'|%).){1,8}$/")
  //  // 判断 even 是否包含特殊字符
  //  if(flag.test(even)){
  //     return true;
  //  }
  return false;
}
function getVersion() {
  return axios.get(Const.server + 'dict?key=version')
    .then(function (response) {
      return response.data.data.value;
    })
}
function checkVip(Dialog, router) {
  if (Const.mUser.vip != 1) {
    Dialog.confirm({
      title: '您还不是VIP',
      message: '请升级为VIP'
    }).then(() => {
      router.push('/vip');
    }).catch(() => {
    });
    return false;
  }
  return true;
}

function dataURLtoFile(urlData, filename) {
  //window.console.log("====>"+urlData+"===>"+filename)
  let arr = urlData.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bytes = window.atob(arr[1]); // 解码base64
  // let bytes = Buffer.from(arr[1],'base64');// 解码base64
  let n = bytes.length
  // window.console.log("====>"+n)
  let ia = new Uint8Array(n);
  while (n--) {
    ia[n] = bytes.charCodeAt(n);
  }
  return new File([ia], filename, { type: mime });
}

function getSign() {
  return axios.get(Const.server + "sign").then(function (response) {
    return response.data.data;
  })
}

function getCosSign() {
  return axios.get(Const.server + "osign").then(function (response) {
    return response.data.data;
  })
}

function checkBind(Dialog, router) {
  if (Const.mUser.tel == '' || Const.mUser.tel == null) {
    Dialog.confirm({
      title: '手机号尚未绑定',
      message: '是否前往绑定手机号'
    }).then(() => {
      router.push('/bind');
    }).catch(() => {
    });
    return false;
  }
  return true;
}
function checkFreeze(Dialog, router) {
  if (Const.mUser.freeze != 0) {
    Dialog.confirm({
      title: '您的账户已被冻结',
      message: Const.mUser.reason
    }).then(() => {
      router.push('/contact');
    }).catch(() => {

    });
    return false;
  }
  return true;
}

function checkFreezeW(Dialog, router) {
  if (Const.mUser.freezeW != 0) {
    Dialog.confirm({
      title: '提现已被冻结',
      message: '有问题请联系客服。'
    }).then(() => {
      router.push('/contact');
    }).catch(() => {

    });
    return false;
  }
  return true;
}

function checkExt(ext) {
  if (ext != '' || ext != null) {
    return Const.exts.indexOf(ext) >= 0;
  }
  return false;
}

function checkVideo(ext) {
  if (ext != '' || ext != null) {
    return Const.extVideo.indexOf(ext) >= 0;
  }
  return false;
}

function checkImg(ext) {
  if (ext != '' || ext != null) {
    return Const.extImg.indexOf(ext) >= 0;
  }
  return false;
}

function checkDoc(ext) {
  if (ext != '' || ext != null) {
    return Const.extDoc.indexOf(ext) >= 0;
  }
  return false;
}


function user(fn) {
  let config = {
    headers: {
      "token": Const.token
    }
  };
  axios
    .get(Const.server + "user/info", config)
    .then(response => {
      if (response.data.code == 0) {
        let user = response.data.data;
        localStorage.setItem('user', JSON.stringify(user));
        Const.user = JSON.stringify(user);
        if (fn) {
          fn(user)
        }
      } else {
        // Toast(response.data.msg);
        router.push("/default")
      }
    }).catch(error => {
      // window.console.log(error)
    });
}

function share(url, title, desc, img, link, fn) {
  let data = {
    url: encodeURIComponent(url),
    link: encodeURIComponent(link),
    "token": Const.token
  };
  axios.post(Const.server + "share", data)
    .then(response => {
      let appId = response.data.data.appId
      let timestamp = response.data.data.timestamp;
      let nonceStr = response.data.data.nonceStr;
      let signature = response.data.data.signature;
      let jsApiList = [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone'
      ];
      if (fn) {
        jsApiList.push('openLocation');
        jsApiList.push('getLocation')
      }
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识，填自己的！
        timestamp: timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: jsApiList
      })
      wx.ready(function () {
        //分享给朋友
        wx.updateAppMessageShareData({
          title: title,
          desc: desc,
          link: link,
          imgUrl: img,
          success: function () {
            // alert('success', msg)
          },
          cancel: function () {
            // window.console.log(2);
          }
        });
        wx.updateTimelineShareData({
          title: title, // 分享标题
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: img, // 分享图标
          success: function () {
            // 设置成功
            // window.console.log('share-->ok-->');
          }
        });
        if (fn) {
          fn();
        }
      })
    }).catch(error => {
      window.console.log(error, 1111)
    });
  user();
}
function toAuth() {
  let url = location.href;
  let redirect_uri = url,
    appid = "wxf9306fe90d555537"; //redirect_uri，授权登陆后的回调地址，需要进行encodeURIComponent处理
  window.location.href =
    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
    appid +
    "&redirect_uri=" +
    redirect_uri +
    "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
}
function matchType(fileName) {
  // 后缀获取
  var suffix = ''
  // 获取类型结果
  var result = ''
  try {
    var flieArr = fileName.split('.')
    suffix = flieArr[flieArr.length - 1]
  } catch (err) {
    suffix = ''
  }
  // 图片格式
  var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp'];
  // 进行图片匹配
  result = imglist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'image';
    return result;
  };
  // 匹配txt
  var txtlist = ['txt'];
  result = txtlist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'txt';
    return result;
  };
  // 匹配 excel
  var excelist = ['xls', 'xlsx'];
  result = excelist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'excel';
    return result;
  };
  // 匹配 word
  var wordlist = ['doc', 'docx'];
  result = wordlist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'word';
    return result;
  };
  // 匹配 pdf
  var pdflist = ['pdf'];
  result = pdflist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'pdf';
    return result;
  };
  // 匹配 ppt
  var pptlist = ['ppt'];
  result = pptlist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'ppt';
    return result;
  };
  // 匹配 视频
  var videolist = ['mp4', 'm2v', 'mkv'];
  result = videolist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'video';
    return result;
  };
  // 匹配 音频
  var radiolist = ['mp3', 'wav', 'wmv'];
  result = radiolist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'radio';
    return result;
  }
  // 其他 文件类型
  result = 'other';
  return result;
}

export default {
  toAuth,
  text,
  share,
  user,
  getSign,
  getCosSign,
  dataURLtoFile,
  checkVip,
  checkBind,
  checkFreeze,
  checkFreezeW,
  checkExt,
  checkVideo,
  checkDoc,
  checkImg,
  checkReg,
  getVersion,
  matchType
}