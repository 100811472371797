<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
		export default {
		name: 'App',
		provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
			return {
			reload: this.reload                                              
			}
		},
		data() {
			return{
			isRouterAlive: true                    //控制视图是否显示的变量
			}
		},
		mounted () {
			// const script = document.createElement('script')
			// //script.src = '@/assets/js/s9.js'
			// script.src = require('@/assets/js/s9.js')
			// script.language = 'JavaScript'
			// document.body.appendChild(script)
			this.isWeChat();
		},
		methods: {
			reload () {
				this.isRouterAlive = false;            //先关闭，
				this.$nextTick(function () {
				this.isRouterAlive = true;         //再打开
				}) 
			},
			isWeChat: function () {
				let ua = window.navigator.userAgent.toLowerCase()
				if (ua.match(/MicroMessenger/i) == 'micromessenger') {//判断微信
					let style = document.createElement('style');
					style.type = 'text/css';
					style.innerHTML="uni-page-head,.uni-page-head{display:none;}";
					document.getElementsByTagName('head').item(0).appendChild(style);
				}
			},
		}
		}
</script>
<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
uni-page-head
{
    display: none;
}
</style>
