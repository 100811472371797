import Vue from "vue";
import App from "./App";
import { router } from "./router";
import "@/styles/common.css";
import Moment from "moment";
import "moment/locale/zh-cn";
import "@/assets/js/rem";
import common from "@/assets/js/common";
import emoji from "@/assets/js/emoji";
import "./assets/icon/video/iconfont.css";
import './assets/ckeditor.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';

//import TcPlayer  from '@/assets/js/TcPlayer-module-2.4.1'

// const TCPlayer = window.TCPlayer
// Vue.prototype.TCPlayer = TCPlayer

// const defaultTitle = '我的工作室'
// router.beforeEach((to, from, next) => {
//  document.title = to.meta.title ? to.meta.title : defaultTitle
//  next()
// })

import VueClipBoard from 'vue-clipboard2'

Vue.use(VueClipBoard)
Vue.use(CKEditor)

// 定义全局时间戳过滤器
Vue.filter("formatDate", function (value, format) {
  return Moment(value).format(format);
});

Vue.prototype.common = common;
// Vue.prototype.TcPlayer = TcPlayer

Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
  update: function (el) {
    document.title = el.dataset.title;
  },
});

Vue.directive("emoji", emoji);

new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});

// document.addEventListener("WeixinJSBridgeReady", function () {
//   WeixinJSBridge.invoke("setFontSizeCallback", {
//     fontSize: '2'
//   });
// }, false);

// (function() {
//   if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
//      handleFontSize();
//    } else {
//      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
//    }
//    function handleFontSize() {
//      // 设置网页字体为默认大小
//      WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
//      // 重写设置网页字体大小的事件
//      WeixinJSBridge.on('menu:setfont', function() {
//        WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
//      });
//    }
// })();
